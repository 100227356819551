import React from 'react';
import ReactApexChart from 'react-apexcharts';
const Chart = ({
    seriesData,
    seriesData2
}) => {
    // 차트 옵션 설정
    const chartOptions = {
        xaxis: {
            categories: [],
            labels: {
                formatter: function (value) {
                    return value + '월';
                },
            },
        },
        yaxis: {
            labels: {
                offsetX: -10,
                formatter: function (value) {
                    return value.toFixed(0);
                },
            },
        },
        chart: {
            animations: {
                enabled: false,
            },
            shadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 1,
            },
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: true,
        },
        stroke: {
            curve: 'smooth',
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5,
            },
            padding: {
                left: 0,
            },
        },
        markers: {
            size: 6,
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val.toFixed(2);
                }
            }
        }
    };

    // 두 번째 차트 옵션 설정
    const chartOptions2 = {
        chart: {
            animations: {
                enabled: false,
            },
            stacked: true,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: true,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: [],
            max: 12,
            labels: {
                formatter: function (value) {
                    return Number(value).toFixed(0) + '월';
                },
            },
        },
        yaxis: {
            labels: {
                offsetX: -10,
                formatter: function (value) {
                    return value.toFixed(0);
                },
            },
        },
        fill: {
            opacity: 1,
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5,
            },
            padding: {
                left: 0,
            },
        },
        markers: {
            size: 6,
        },
        tooltip: {
            y: {
                formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                    var total = 0;
                    w.config.series.forEach((series, index) => {
                        if (series.name !== '보유') {
                            total += parseFloat(series.data[dataPointIndex]) || 0;
                        }
                    });

                    if (seriesIndex !== 0) {
                        var percentage = total > 0 ? ((parseFloat(val) / total) * 100).toFixed(2) : 0;
                        return `${parseFloat(val).toFixed(2)} (${percentage}%)`;
                    } else {
                        return `${parseFloat(val).toFixed(2)}`;
                    }
                }
            }
        }
    };
    return (
        <div className='bx'>
            <ReactApexChart
                type="line"
                width={348}
                height={300}
                options={chartOptions}
                series={seriesData}
            />

            <ReactApexChart
                type="line"
                width={348}
                height={300}
                options={chartOptions2}
                series={seriesData2}
            />
        </div>
    )
}

export default Chart;
