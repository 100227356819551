import { FETCH_DATA_BEGIN, FETCH_DATA_SUCCESS, FETCH_DATA_FAILURE, SET_DEPT_CODES } from '../actions/dataActions';

const initialState = {
  deptAssign: {
    data: [],
    loading: false,
    error: null,
  },
  deptAssignAll: {
    data: [],
    loading: false,
    error: null,
  },
  deptAssignSingle: {
    data: [],
    loading: false,
    error: null,
  },
  deptUse: {
    data: [],
    loading: false,
    error: null,
  },
  deptStat: {
    data: [],
    loading: false,
    error: null,
  },
  depts: {
    data: [],
    loading: false,
    error: null,
  },
  deptPeriod: {
    data: [],
    loading: false,
    error: null,
  },
  deptCodes: [],
  deptMemberStat: {
    data: [],
    loading: false,
    error: null,
  },
  deptMemberDetail: {
    data: [],
    loading: false,
    error: null,
  },
  deptMemberDetail2: {
    data: [],
    loading: false,
    error: null,
  },
  pjtStat: {
    data: [],
    loading: false,
    error: null,
  },
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_BEGIN:
      return {
        ...state,
        [action.payload.target]: {
          ...state[action.payload.target],
          loading: true,
          error: null,
        },
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        [action.payload.target]: {
          ...state[action.payload.target],
          loading: false,
          data: action.payload.data,
        },
      };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        [action.payload.target]: {
          ...state[action.payload.target],
          loading: false,
          error: action.payload.error,
        },
      };
    case SET_DEPT_CODES:
      return {
        ...state,
        deptCodes: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;