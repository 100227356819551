import React from 'react';
import { Table } from 'semantic-ui-react';
const DeptMemberStatDetail2 = ({
  memberDetail,
  totalSum,
  rtnMM,
  workDay,
  assignSum,
  sumData,
  updatedUniqueTaskInfo,
  rowSpans,
  sumByProjectArray,
  rtnRatio,
  sumsArray,
  weekendDays,
  pjtNo
}) => {

  let adjustedSumData = { ...sumData }; // sumData 복사본 생성

  updatedUniqueTaskInfo.forEach(item => {
    if (!(item.pjt_no > 1 && item.pjt_no === pjtNo)) {
      Object.keys(item).forEach(key => {
        if (key.match(/^\d{2}$/)) { // 날짜에 해당하는 키인지 확인
          const newValue = (adjustedSumData[key] || 0) - item[key];
          adjustedSumData[key] = newValue === 0 ? '' : newValue; // 0이면 빈 문자열, 아니면 계산된 값 사용
        }
      });
    }
  });

  // 필터링되지 않는 항목들의 sch_mm 합계 계산
  let excludedSchMmTotal = 0;
  updatedUniqueTaskInfo.forEach(item => {
    if (!(item.pjt_no > 1 && item.pjt_no === pjtNo)) {
      excludedSchMmTotal += item.sum;
    }
  });

  // totalSum에서 필터링되지 않는 항목들의 sch_mm 합계 빼기
  let adjustedTotalSum = totalSum - excludedSchMmTotal;

  const updatedUniqueTaskInfoResult = updatedUniqueTaskInfo.filter(item => item.pjt_no > 1 && item.pjt_no === pjtNo);

  let adjustedRowSpans = updatedUniqueTaskInfoResult.map((item, index, array) => {
    if (index > 0 && item.pjt_nm === array[index - 1].pjt_nm) {
      return 0; // 이전 항목과 프로젝트 이름이 같으면 0을 반환
    }
    // 연속 중복 카운트 계산
    let span = 1;
    for (let j = index + 1; j < array.length && item.pjt_nm === array[j].pjt_nm; j++) {
      span++;
    }
    return span;
  });

  return (
    <div>
      <Table celled unstackable className='tbl_type v4 unstackable'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>구분</Table.HeaderCell>
            <Table.HeaderCell>상세</Table.HeaderCell>
            <Table.HeaderCell>MD</Table.HeaderCell>
            <Table.HeaderCell>MM</Table.HeaderCell>
            {[...Array(memberDetail && memberDetail.day.length).keys()].map(day => (
              <Table.HeaderCell key={day}>{day + 1}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Footer>
          <Table.Row>
            <Table.Cell colSpan='2'>합계 (WD : {workDay})</Table.Cell>
            <Table.Cell>{rtnMM(adjustedTotalSum, 1)}</Table.Cell>
            <Table.Cell>{rtnMM(adjustedTotalSum, workDay)}</Table.Cell>
            {[...Array(memberDetail && memberDetail.day.length).keys()].map((day) => {
              const paddedDay = String(day + 1).padStart(2, '0');
              return (
                <Table.Cell key={day}>
                  {adjustedSumData[paddedDay]}
                </Table.Cell>
              );
            })}
          </Table.Row>
        </Table.Footer>

        <Table.Body>
          {updatedUniqueTaskInfoResult && updatedUniqueTaskInfoResult.map((item, key) => (
            <Table.Row key={key}>
              {
                adjustedRowSpans[key] !== 0 && (
                  <Table.Cell rowSpan={adjustedRowSpans[key]}><span className='txt' title={item.pjt_nm}>{item.pjt_nm}</span></Table.Cell>
                )
              }
              <Table.Cell className='td_type'><span className='txt' title={item.busi_desc}>{item.busi_desc}</span></Table.Cell>
              <Table.Cell>{rtnMM(item.sum, 1)}</Table.Cell>
              {
                adjustedRowSpans[key] !== 0 && (
                  <Table.Cell rowSpan={adjustedRowSpans[key]}>{rtnMM(sumByProjectArray[item.pjt_nm], workDay)}</Table.Cell>
                )}
              {[...Array(memberDetail && memberDetail.day.length).keys()].map((day) => {
                const paddedDay = String(day + 1).padStart(2, '0');
                const isWeekend = weekendDays.has(paddedDay); // 주말 여부 확인
                const cellClassName = isWeekend ? 'td_type2' : ''; // 주말이면 클래스 추가
                return (
                  <Table.Cell key={day} className={cellClassName}>
                    {item[paddedDay]}
                  </Table.Cell>
                );
              })}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

export default DeptMemberStatDetail2;
