import React from 'react';

const SearchBar = ({ keyword, setKeyword }) => {
  const handleInputChange = (event) => {
    setKeyword(event.target.value);
  };

  return (
    <div className="ui icon input searchbar">
      <input 
        type="text" 
        placeholder="검색..." 
        value={keyword} 
        onChange={handleInputChange}
      />
      <i className="search icon"></i>
    </div>
  );
};

export default SearchBar;
