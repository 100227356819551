import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  deptAssign: {
    data: [],
    loading: false,
    error: null,
  },
  deptAssignAll: {
    data: [],
    loading: false,
    error: null,
  },
  deptAssignSingle: {
    data: [],
    loading: false,
    error: null,
  },
  deptUse: {
    data: [],
    loading: false,
    error: null,
  },
  deptStat: {
    data: [],
    loading: false,
    error: null,
  },
  depts: {
    data: [],
    loading: false,
    error: null,
  },
  deptPeriod: {
    data: [],
    loading: false,
    error: null,
  },
  deptCodes: [],
  deptMemberStat: {
    data: [],
    loading: false,
    error: null,
  },
  deptMemberDetail: {
    data: [],
    loading: false,
    error: null,
  },
  deptMemberDetail2: {
    data: [],
    loading: false,
    error: null,
  },
  pjtStat: {
    data: [],
    loading: false,
    error: null,
  },
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    projectsLoading(state, action) {
      const target = action.payload !== undefined ? action.payload : 'projects';
      state[target].loading = true;
      state[target].error = null;
    },
    projectsLoaded(state, action) {
      const target = action.payload !== undefined ? action.payload.target : 'projects';
      state[target].loading = false;
      state[target].data = action.payload.data;
    },
    projectsLoadError(state, action) {
      const target = action.payload !== undefined ? action.payload.target : 'projects';
      state[target].loading = false;
      state[target].error = action.payload.error;
    },
  },
});

export const {
  projectsLoading,
  projectsLoaded,
  projectsLoadError,
} = dataSlice.actions;

export default dataSlice.reducer;
