import React from 'react';
import ReactApexChart from 'react-apexcharts';
import ProjectTableData from './ProjectTableData'
const Chart2 = ({
    seriesData3,
    seriesData4,
    seriesData5,
    seriesData6,
    labels3,
    labels4,
    labels5,
    labels6,
    deptNm
}) => {
    // const labelsData = labels3.map(item => item.clt_nm);
    const labelsData2 = labels4.map(item => item.clt_nm);
    const labelsData3 = labels5.map(item => item.clt_nm);
    let labelsData4 = []
    if(labels6){
        labelsData4 = labels6.map(item => item.clt_nm);
    }
    // console.log(seriesData6)

    // 차트 옵션 설정
    const getChartOptions = (categories, rotateLabel) => ({
        xaxis: {
            categories,
            labels: {
                formatter: function (value) {
                    return value;
                },
                rotate: rotateLabel ? -45 : 0,
                rotateAlways: rotateLabel ? true : false,
                style: {
                    fontSize: '12px',
                    cssClass: 'apexcharts-xaxis-label',
                },
            },
        },
        yaxis: {
            labels: {
                offsetX: -10,
                formatter: function (value) {
                    return isNaN(value) ? value : Math.floor(value).toLocaleString();
                },
            },
        },
        chart: {
            animations: {
                enabled: false,
            },
            shadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 1,
            },
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return isNaN(val) ? val : Math.floor(val).toLocaleString();
            },
        },
        stroke: {
            curve: 'smooth',
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5,
            },
            padding: {
                left: 0,
                right: 0,
                bottom: 10
            }
        },
        markers: {
            size: 6,
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return isNaN(val) ? val : Math.floor(val).toLocaleString();
                }
            }
        }
    });
    return (
        <div>
            <h2>{deptNm} 매출 현황</h2>
            <div className='bx'>
                <ReactApexChart
                    type="line"
                    width={1440}
                    height={500}
                    options={getChartOptions(labelsData4)}
                    series={seriesData6}
                />
            </div>
            <div className='bx4'>
                <ProjectTableData
                    data={labels6}
                />
            </div>
            <h2>전사 매출 현황 ({deptNm} 참여)</h2>
            {/* <div className='bx'>
                <ReactApexChart
                    type="line"
                    width={1440}
                    height={500}
                    options={chartOptions}
                    series={seriesData3}
                />
            </div> */}
            <div className='bx'>
                <ReactApexChart
                    type="line"
                    width={1440}
                    height={500}
                    options={getChartOptions(labelsData2)}
                    series={seriesData4}
                />
            </div>
            <div className='bx4'>
                <ProjectTableData
                    data={labels4}
                />
            </div>
            <h2>전사 매출 현황 (All)</h2>
            <div className='bx'>
                <ReactApexChart
                    type="line"
                    width={1440}
                    height={500}
                    options={getChartOptions(labelsData3)}
                    series={seriesData5}
                />
            </div>
            <div className='bx4'>
                <ProjectTableData
                    data={labels5}
                />
            </div>
        </div>
    )
}

export default Chart2;
