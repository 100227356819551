import React from 'react';
import { Table } from 'semantic-ui-react';

const ProjectTableData = ({ data }) => {
    // 데이터의 avg_price * total_sch_mm 의 전체 합계를 계산
    const totalContribution = data && data.reduce((total, item) => {
        return total + (parseInt(item.avg_price.replace(/,/g, '')) * item.total_sch_mm);
    }, 0);

    return (
        <div>
            <Table celled unstackable className='ui celled table tbl_type v2 unstackable'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>No.</Table.HeaderCell>
                        <Table.HeaderCell>고객사</Table.HeaderCell>
                        <Table.HeaderCell>매출</Table.HeaderCell>
                        <Table.HeaderCell>평단가</Table.HeaderCell>
                        <Table.HeaderCell>매출 기여도</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data && data.map((item, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>{index + 1}</Table.Cell>
                            <Table.Cell>{item.clt_nm}</Table.Cell>
                            <Table.Cell>{(parseInt(parseInt(item.avg_price.replace(/,/g, '')) * item.total_sch_mm)).toLocaleString()}</Table.Cell>
                            <Table.Cell>{(parseInt(item.avg_price.replace(/,/g, '')).toLocaleString())}</Table.Cell>
                            <Table.Cell>{((parseInt(item.avg_price.replace(/,/g, '')) * item.total_sch_mm) / totalContribution * 100).toFixed(2)}%</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    )
}

export default ProjectTableData;
