import React from 'react';
import { Table } from 'semantic-ui-react';
const DeptMemberStatDetail = ({
    memberDetail,
    totalSum,
    rtnMM,
    workDay,
    assignSum,
    sumData,
    updatedUniqueTaskInfo,
    rowSpans,
    sumByProjectArray,
    rtnRatio,
    sumsArray,
    weekendDays,
    handleButtonClick5
}) => {
    
    return (
        <div>
            <Table celled unstackable className='tbl_type v4 unstackable'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>구분</Table.HeaderCell>
                  <Table.HeaderCell>상세</Table.HeaderCell>
                  <Table.HeaderCell>MD</Table.HeaderCell>
                  <Table.HeaderCell>MM</Table.HeaderCell>
                  <Table.HeaderCell>배정</Table.HeaderCell>
                  <Table.HeaderCell>투입률</Table.HeaderCell>
                  <Table.HeaderCell>사용율</Table.HeaderCell>
                  {[...Array(memberDetail && memberDetail.day.length).keys()].map(day => (
                    <Table.HeaderCell key={day}>{day + 1}</Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>

              <Table.Footer>
                <Table.Row>
                  <Table.Cell colSpan='2'>합계 (WD : {workDay})</Table.Cell>
                  <Table.Cell>{rtnMM(totalSum, 1)}</Table.Cell>
                  <Table.Cell>{rtnMM(totalSum, workDay)}</Table.Cell>
                  <Table.Cell>{assignSum}</Table.Cell>
                  <Table.Cell>-</Table.Cell>
                  <Table.Cell>-</Table.Cell>
                  {[...Array(memberDetail && memberDetail.day.length).keys()].map((day) => {
                    const paddedDay = String(day + 1).padStart(2, '0');
                    return (
                      <Table.Cell key={day}>
                        {sumData[paddedDay]}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              </Table.Footer>

              <Table.Body>
                {updatedUniqueTaskInfo && updatedUniqueTaskInfo.map((item, key) => (
                  <Table.Row key={key}>
                  {
                    rowSpans[key] !== 0 && (
                      <Table.Cell rowSpan={rowSpans[key]}>
                        {
                          item.pjt_nm === "근태" || item.pjt_nm === "조직 및 개인" ? (
                            <span className='txt' title={item.pjt_nm}>
                              {item.pjt_nm}
                            </span>
                          ) : (
                            <button
                              type="button"
                              className="btn_type v1"
                              onClick={() => handleButtonClick5(item.pjt_no)}
                            >
                              <span className='txt' title={item.pjt_nm}>
                                {item.pjt_nm}
                              </span>
                            </button>
                          )
                        }
                      </Table.Cell>
                    )
                  }
                    <Table.Cell className='td_type'><span className='txt' title={item.busi_desc}>{item.busi_desc}</span></Table.Cell>
                    <Table.Cell>{rtnMM(item.sum, 1)}</Table.Cell>
                    {
                      rowSpans[key] !== 0 && (
                        <Table.Cell rowSpan={rowSpans[key]}>{rtnMM(sumByProjectArray[item.pjt_nm], workDay)}</Table.Cell>
                      )}
                    {
                      rowSpans[key] !== 0 && (
                        <Table.Cell rowSpan={rowSpans[key]}>{item.sch_mm}</Table.Cell>
                      )}
                    {
                      rowSpans[key] !== 0 && (
                        <Table.Cell rowSpan={rowSpans[key]}>{rtnRatio(sumByProjectArray[item.pjt_nm], workDay, item.sch_mm)}</Table.Cell>
                      )}
                    {
                      rowSpans[key] !== 0 && (
                        <Table.Cell rowSpan={rowSpans[key]}>{((sumsArray[key] / 8) / rtnMM(totalSum, 1) * 100).toFixed(1)}%</Table.Cell>
                      )}
                    {[...Array(memberDetail && memberDetail.day.length).keys()].map((day) => {
                      const paddedDay = String(day + 1).padStart(2, '0');
                      const isWeekend = weekendDays.has(paddedDay); // 주말 여부 확인
                      const cellClassName = isWeekend ? 'td_type2' : ''; // 주말이면 클래스 추가
                      return (
                        <Table.Cell key={day} className={cellClassName}>
                          {item[paddedDay]}
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
        </div>
    )
}

export default DeptMemberStatDetail;
